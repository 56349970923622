import React from "react";
import { useState } from "react";
import LayoutBox from "./LayoutBox";
import theme from "../config/theme.json";
import { useAuth } from "../context/AuthContext";

const AuthModal = () => {
  const [accessCode, setAccessCode] = useState(localStorage.getItem("token"));

  const { cancelAuth, login, error, beginRequestCode } = useAuth();

  const handleSubmit = () => {
    login(accessCode);
  };

  const handleRequest = () => {
    beginRequestCode();
  };

  const handleCancel = () => {
    cancelAuth();
  };

  return (
    <>
      <div className="dim" onClick={handleCancel} />
      <div className="modal">
        <LayoutBox title="Enter Access Code">
          <input
            type="text"
            placeholder="Access Code"
            id="access-code"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            style={{
              borderColor: theme.borderColor,
              backgroundColor: theme.lighterBackgroundColor,
              color: theme.textColor,
            }}
          />
          {error && <span className="error">{error}</span>}
          <div className="button-bar-row" style={{ textAlign: "center" }}>
            <div className="button-bar" style={{ borderColor: theme.colorB }}>
              <a style={{ borderColor: theme.colorB }} onClick={handleSubmit}>
                Unlock
              </a>
              <a style={{ borderColor: theme.colorB }} onClick={handleRequest}>
                Request Code
              </a>
              <a style={{ borderColor: theme.colorB }} onClick={handleCancel}>
                Cancel
              </a>
            </div>
          </div>
        </LayoutBox>
      </div>
    </>
  );
};

export default AuthModal;
