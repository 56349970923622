import React, { useState } from "react";
import theme from "../config/theme.json";
import { useAuth } from "../context/AuthContext";
import { getCodeUrl } from "../api/auth";

const ButtonBar = ({ buttons }) => {
  const { beginAuth, isAuthenticated } = useAuth();
  return (
    <>
      <div className="button-bar-row">
        <div className="button-bar" style={{ borderColor: theme.colorB }}>
          {buttons.map((button) => (
            <a
              style={{ borderColor: theme.colorB }}
              href={
                (!button.requiresAuth || isAuthenticated) &&
                (button.projectId ? getCodeUrl(button.projectId) : button.url)
              }
              onClick={() => {
                if (button.onClick) {
                  button.onClick();
                } else {
                  if (
                    button.requiresAuth &&
                    (!isAuthenticated || !button.projectId)
                  ) {
                    beginAuth(button.projectId);
                  }
                }
              }}
              target="_blank"
            >
              {button.label}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};

export default ButtonBar;
