import theme from "./theme.json";
import BulletedList from "../components/BulletedList";

export const projects = [
  {
    id: 1,
    name: "Digital (AI) Financial Analyst",
    subtitle: "QuAIL Technologies",
    website: "https://www.quantumai.dev/",
    content: (
      <>
        <p>
          Position: <span style={{ color: theme.colorA }}>Senior Software Engineer</span>
        </p>
        In 2023 I accepted a full-time software engineering position at an AI startup called{" "}
        <span style={{ color: theme.colorB }}>QuAIL Technologies</span>. During my time here I've
        been on the front line of the AI wave, actively engaged in helping ease the integration of
        AI into the field of software engineering, as well as into society as a whole.
        <BulletedList
          items={[
            <>
              Developed and shipped full stack <span style={{ color: theme.colorA }}>AI</span>-based
              software (<span style={{ color: theme.colorC }}>Python/Django</span>,{" "}
              <span style={{ color: theme.colorC }}>React</span>,{" "}
              <span style={{ color: theme.colorC }}>Docker</span>,{" "}
              <span style={{ color: theme.colorC }}>Google Cloud</span>,{" "}
              <span style={{ color: theme.colorC }}>Postgres</span>,{" "}
              <span style={{ color: theme.colorC }}>RabbitMQ</span>) in a fast-paced startup
              environment.
            </>,
            <>
              Architected a reliably-performing <span style={{ color: theme.colorA }}>AI</span>{" "}
              system using a mix of industry-standard techniques and my own emerging approach to
              building reliable software around non-deterministic systems.
            </>,
            <>
              Implemented a mix of generative and agentic features tailored toward replicating the
              behavior and performing the responsibilities of a financial analyst.
            </>,
            <>
              Devised and implemented an automated{" "}
              <span style={{ color: theme.colorC }}>performance benchmarking system</span> to ensure
              consistent model performance, achieving a steady 95% success rate on a benchmark set
              of investment-related questions.
            </>,
            <>
              Implemented a data provenance system similar to Perplexity AI to increase the user’s
              confidence in the output by providing a window into the sources of the data.
            </>,
            <>
              Built out numerous <span style={{ color: theme.colorC }}>integrations</span> with
              investment and market data providers, including Morningstar, Plaid, Orion, EODHD,
              Intrinio, Factset, and Cannon Valley, as well as CRMs like Wealthbox and SmartOffice,
              and peripheral integrations like LinkedIn and Stripe.
            </>,
            <>
              Advocated for, implemented, and maintained a suite of integration and end-to-end tests
              (<span style={{ color: theme.colorC }}>Cypress</span> and Python{" "}
              <span style={{ color: theme.colorC }}>unittest</span>).
            </>,
          ]}
        />
      </>
    ),
  },
  {
    id: 2,
    name: "DNA Analysis Software",
    subtitle: "MysteryFam",
    demo: "https://mysteryfam.com",
    source: "Xp4LNWnXbY8X/mysteryfam-sample",
    content: (
      <>
        <p>
          <span style={{ color: theme.colorB }}>MysteryFam™</span> is a suite of DNA analysis tools
          I developed to help adoptees analyze their DNA matches and solve cases of unknown
          parentage, offering analysis and insights based on the latest genetic science.
        </p>
        <p>
          All functionality exposed by the graphical interface is also available, with the proper
          authentication, by performing operations directly on the REST API (
          <a href="https://api.mysteryfam.com" target="_blank" style={{ color: theme.colorC }}>
            https://api.mysteryfam.com
          </a>
          ), which opens up the possibility for users to develop their own third-party applications
          to extend the functionality.
        </p>
      </>
    ),
    media: [
      { url: "https://www.youtube.com/watch?v=9s7EaV0OhSQ" },
      { url: "/mysteryfam/mysteryfam1.png" },
      { url: "/mysteryfam/mysteryfam2.png" },
      { url: "/mysteryfam/mysteryfam3.png" },
    ],
  },
  {
    id: 3,
    name: "Monetization Tools for Podcasters",
    subtitle: "Libsyn",
    website: "https://libsyn.com/",
    content: (
      <>
        <p>
          Position: <span style={{ color: theme.colorA }}>Web Application Developer III</span>{" "}
          (Senior, Full Stack)
        </p>
        During my time at <span style={{ color: theme.colorB }}>Libsyn</span> I focused on software
        geared toward podcast monetization, including subscription-based content and programmatic
        advertising.
        <BulletedList
          items={[
            <>
              Developed and shipped containerized, cloud-based software for a popular podcasting
              platform, using a variety of languages, frameworks, and tools including{" "}
              <span style={{ color: theme.colorC }}>Python</span>,{" "}
              <span style={{ color: theme.colorC }}>Django</span>,{" "}
              <span style={{ color: theme.colorC }}>React</span>,{" "}
              <span style={{ color: theme.colorC }}>Docker</span>,{" "}
              <span style={{ color: theme.colorC }}>AWS</span>,{" "}
              <span style={{ color: theme.colorC }}>Postgres</span>,{" "}
              <span style={{ color: theme.colorC }}>Redis</span>,{" "}
              <span style={{ color: theme.colorC }}>DynamoDB</span>,{" "}
              <span style={{ color: theme.colorC }}>Flask</span>,{" "}
            </>,
            <>
              Specialized in software geared toward podcast monetization, including
              subscription-based content and programmatic advertising.
            </>,
            <>
              Led a major{" "}
              <span style={{ color: theme.colorC }}>refactor/re-architecture project</span> on an
              acquired asset, leading to a{" "}
              <span style={{ color: theme.colorC }}>90% reduction</span> in the frequency of support
              tickets.
            </>,
            <>
              Was a key integration contributor and early adopter of{" "}
              <span style={{ color: theme.colorC }}>Spotify Open Access</span>.
            </>,
            <>
              Wrote <span style={{ color: theme.colorC }}>unit</span>,{" "}
              <span style={{ color: theme.colorC }}>integration</span>, and{" "}
              <span style={{ color: theme.colorC }}>end-to-end tests</span> (
              <span style={{ color: theme.colorC }}>Jest</span>,{" "}
              <span style={{ color: theme.colorC }}>RTL</span>,{" "}
              <span style={{ color: theme.colorC }}>Cypress</span>) and advocated for more
              test-writing among engineers.
            </>,
            <>
              Successfully optimized several existing features that were performing poorly at{" "}
              <span style={{ color: theme.colorC }}>scale</span>.
            </>,
            <>
              Worked with DBMS / data stores including{" "}
              <span style={{ color: theme.colorC }}>MySQL</span>,{" "}
              <span style={{ color: theme.colorC }}>Postgres</span>,{" "}
              <span style={{ color: theme.colorC }}>DynamoDB</span>,{" "}
              <span style={{ color: theme.colorC }}>Redis</span>.
            </>,
            <>
              Participated in various <span style={{ color: theme.colorC }}>scrum</span> ceremonies
              and developed software increments within an{" "}
              <span style={{ color: theme.colorC }}>Agile</span> framework.
            </>,
            <>
              Demonstrated <span style={{ color: theme.colorC }}>technical leadership</span> and
              provided <span style={{ color: theme.colorC }}>mentorship</span> to other developers.
            </>,
          ]}
        />
      </>
    ),
  },
  {
    id: 4,
    name: "Audiobook Streaming Service",
    subtitle: "My BVL",
    demoDesktop: "https://bhaktivedantavediclibrary.org/register/",
    demoAndroid: "https://play.google.com/store/apps/details?id=com.beyond.bvl",
    demoApple: "https://apps.apple.com/us/app/my-bvl/id1452929393?ls=1",
    deprecated: true,
    content: (
      <>
        <p>
          <span style={{ color: theme.colorB }}>My BVL</span> was developed to provide access to a
          library of audiobooks and video resources. User account system keeps track of listening
          progress for registered users to be easily resumed from one device to the next.
        </p>
        <p>
          A mobile app was also developed to offer an improved mobile-friendly audio player
          interface, with several additional features. Offline Mode allows you to download and
          listen to audiobooks without an internet connection. Listening progress is now stored for
          each book individually, allowing you to maintain your current progress in multiple books
          at once (app listening progress is also synced with the website). A sleep timer feature
          was also added.
        </p>
      </>
    ),
    media: [
      { url: "/bvl/mybvl.jpg", orientation: "vertical" },
      { url: "/bvl/book.jpg", orientation: "vertical" },
      { url: "/bvl/audio-books.jpg", orientation: "vertical" },
      { url: "/bvl/app1.jpg", orientation: "vertical" },
      { url: "/bvl/app3-1.png", orientation: "vertical" },
      { url: "/bvl/app4.png", orientation: "vertical" },
    ],
  },
  {
    id: 5,
    name: "License Management System",
    subtitle: "Polyhedron Physics",
    demo: "https://polyhedronphysics.com/register/",
    deprecated: true,
    content: (
      <>
        <p>
          The <span style={{ color: theme.colorB }}>Polyhedron Physics Web Portal</span> was
          developed to allow high schools and universities to purchase and distribute access to
          web-based physics labs (labs themselves were developed by client).
        </p>
        <p>
          Features include: license and class management for institutions and instructors; free
          trial for new users; Instructor Dashboard with special teachers-only tools and materials;
          Student Dashboard providing access to owned labs and simulations.
        </p>
      </>
    ),
    media: [
      {
        url: "/polyhedron/polyhedron-student-dashboard.jpg",
        orientation: "vertical",
      },
      {
        url: "/polyhedron/polyhedron-instructor-dashboard.jpg",
        orientation: "vertical",
      },
      {
        url: "/polyhedron/polyhedron-lab.jpg",
      },
    ],
  },
  {
    id: 6,
    name: "Emergency Alerts App",
    subtitle: "Brooke County EMA",
    content: (
      <>
        <p>
          The <span style={{ color: theme.colorB }}>Brooke County Emergency Alerts</span> app was
          developed for a local health department and EMS unit to send out push notifications
          regarding issues relating to public health and safety.
        </p>
        <p>
          Includes both a public-facing <span style={{ color: theme.colorC }}>React Native</span>{" "}
          app, which receives notifications and manages category subscriptions, and an
          administrative <span style={{ color: theme.colorC }}>React</span> app, which enables
          sending and managing notifications. Both apps interact with a{" "}
          <span style={{ color: theme.colorC }}>RESTful web service</span> that was developed to
          provide the required functionality.
        </p>
      </>
    ),
    media: [
      {
        url: "/brookeema/dispatch.png",
        orientation: "vertical",
      },
      {
        url: "/brookeema/home.png",
        orientation: "vertical",
      },
      {
        url: "/brookeema/notification.png",
        orientation: "vertical",
      },
    ],
  },
  {
    id: 7,
    name: "Real Estate App",
    subtitle: "Paull Associates",
    content: (
      <>
        <p>
          The <span style={{ color: theme.colorB }}>Paull Associates</span> real estate app was
          developed to provide an interface for prospective buyers to search, filter, and view
          property listings, as well as to be notified of newly-added properties. An auto-updating
          property listings database was built and integrated with various MLS providers, with the
          ability for the realtor to add additional non-MLS listings and data.
        </p>
        <p>Mobile app adds location-specific features and push notifications.</p>
      </>
    ),
    media: [
      {
        url: "/paull/property-search.jpg",
      },
      {
        url: "/paull/home-scrollers.jpg",
        orientation: "vertical",
      },
      {
        url: "/paull/home-top.jpg",
        orientation: "vertical",
      },
      {
        url: "/paull/app1.png",
        orientation: "vertical",
      },
      {
        url: "/paull/app2.png",
        orientation: "vertical",
      },
      {
        url: "/paull/app3.png",
        orientation: "vertical",
      },
    ],
  },
  {
    id: 8,
    name: "Custom Product Tool",
    subtitle: "NameFrame Factory",
    demo: "https://nameframefactory.com/#design-your-frame",
    content: (
      <>
        <p>
          The <span style={{ color: theme.colorB }}>NameFrame Factory</span> custom product tool was
          developed to provide design tools for and facilitate purchase of custom “Name Frames”
          (where individual photos are used to represent each letter of a word).
        </p>
        <p>
          An easy-to-use Frame Designer tool was developed to allow customers to design their own
          products from a database of frame types, backgrounds, letter variations, etc., and
          purchase them instantly.
        </p>
        <p>
          The Frame Designer was also integrated into the admin interface to allow the client to
          create pre-made designs to list in the online store.
        </p>
      </>
    ),
    media: [
      { url: "https://www.youtube.com/watch?v=3pBqpRz35lc" },
      {
        url: "/nameframes/nameframes-frame-designer.jpg",
        orientation: "vertical",
      },
      {
        url: "/nameframes/shop.jpg",
        orientation: "vertical",
      },
    ],
  },
  {
    id: 9,
    name: "Mobile Game",
    subtitle: "Fluster Thruster",
    content: (
      <>
        <p>
          <span style={{ color: theme.colorB }}>Fluster Thruster</span> is a mobile game I’ve been
          developing as a side project. All aspects of the game are my own work, including the art,
          design, programming, sound effects, etc (though the theme song in the video is temporary).
        </p>
        <p>
          <em>
            NOTE: Screenshots are from an older build. A{" "}
            <span style={{ color: theme.colorC }}>demo</span> and{" "}
            <span style={{ color: theme.colorC }}>source code</span> will be available soon.
          </em>
        </p>
      </>
    ),
    media: [
      { url: "https://www.youtube.com/watch?v=dVPDIyFE87A" },
      { url: "/fluster/fluster1.jpg" },
      { url: "/fluster/fluster2.jpg" },
      { url: "/fluster/fluster4.jpg" },
      { url: "/fluster/fluster5.jpg" },
      { url: "/fluster/fluster3.jpg" },
      { url: "/fluster/fluster6.jpg" },
    ],
  },
];
