import "./App.css";
import theme from "./config/theme.json";
import ProjectsPanel from "./components/ProjectsPanel";
import TabsPanel from "./components/TabsPanel";
import AuthModal from "./components/AuthModal";
import { useState } from "react";
import { useAuth } from "./context/AuthContext";
import RequestCodeModal from "./components/RequestCodeModal";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { projects } from "./config/projects";

function App() {
  const [openProjects, setOpenProjects] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const { isAuthing, isRequestingCode } = useAuth();
  const [showingMenu, setShowingMenu] = useState(false);

  return (
    <div className="App" style={{ backgroundColor: theme.backgroundColor, color: theme.textColor }}>
      <div className="title-bar" style={{ backgroundColor: theme.borderColor }}>
        Jerry Voltz | Software Engineer
        <span className="date-time">
          {new Date().toLocaleDateString()}{" "}
          {new Date().toLocaleTimeString("en-us", { timeStyle: "short" })}
        </span>
        <span className="mobile-menu-button" onClick={() => setShowingMenu(!showingMenu)}>
          <FontAwesomeIcon
            icon={showingMenu ? faXmark : faBars}
            style={{
              color: theme.textColor,
            }}
          />
        </span>
      </div>
      <div className="main-window">
        <ProjectsPanel
          showingMobile={showingMenu}
          projects={projects}
          openProjects={openProjects}
          selectedTab={selectedTab}
          onOpen={(project) => {
            if (openProjects?.filter((p) => p.name == project.name).length == 0) {
              setOpenProjects([...openProjects, project]);
            } else {
              setSelectedTab(openProjects?.find((p) => p.name == project.name).id);
            }
            setShowingMenu(false);
          }}
        />
        <TabsPanel
          selectedTab={selectedTab}
          openProjects={openProjects}
          onClose={(project) => {
            setOpenProjects(openProjects?.filter((p) => p.name != project.name));
          }}
          onChange={setSelectedTab}
        />
      </div>
      {isAuthing && <AuthModal />}
      {isRequestingCode && <RequestCodeModal />}
    </div>
  );
}

export default App;
