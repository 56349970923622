const sendEmail = (fromName, fromEmail, company, message) => {
  window.emailjs.send("default_service", "template_lmqg5ha", {
    fromName,
    fromEmail,
    company,
    message,
  });
};

export { sendEmail };
