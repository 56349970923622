const login = async (token) => {
  if (!token) {
    throw "You must provide an access code.";
  }
  localStorage.setItem("token", token);
};

const logout = async () => {
  localStorage.setItem("token", "");
};

const getCodeUrl = (projectId) => {
  return `https://gitfront.io/r/${localStorage.getItem("token")}/${projectId}/`;
};

const getToken = async () => localStorage.getItem("token");

export { login, logout, getCodeUrl, getToken };
