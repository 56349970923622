import React from "react";

const Tag = ({ name, style }) => {
  return (
    <span className="tag" style={style}>
      {name}
    </span>
  );
};

export default Tag;
