import React, { useState, useContext, useEffect } from "react";
import {
  getToken,
  login as apiLogin,
  logout as apiLogout,
  getCodeUrl,
} from "../api/auth";

const AuthContext = React.createContext();

//manages what to do if the user isn't logged in
//maintains a nice clean isAuthenticated variable
//stores the logged in user object
const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthing, setIsAuthing] = useState(false);
  const [isRequestingCode, setIsRequestingCode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [redirectId, setRedirectId] = useState();

  useEffect(() => {
    getToken().then((token) => {
      login(token, true);
    });
  }, []);

  const beginAuth = (redirectId) => {
    setRedirectId(redirectId);
    setIsAuthing(true);
  };

  const beginRequestCode = () => {
    setIsRequestingCode(true);
    setIsAuthing(false);
  };

  const cancelAuth = () => {
    setError("");
    setIsAuthing(false);
  };

  const cancelRequestCode = () => {
    setIsRequestingCode(false);
  };

  const login = async (code, suppress) => {
    setError("");
    setIsLoading(true);
    await apiLogin(code)
      .then(() => {
        setIsAuthenticated(true);
        setIsAuthing(false);
        setIsLoading(false);
        if (redirectId) {
          window.open(getCodeUrl(redirectId), "_blank");
        }
      })
      .catch((e) => {
        setIsAuthenticated(false);
        setIsLoading(false);
        if (!suppress) {
          setError(e);
        }
      });
  };

  const logout = async () => {
    setIsLoading(true);
    await apiLogout()
      .then(() => {
        setIsAuthenticated(false);
        setIsAuthing(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsAuthing(false);
        setIsLoading(false);
      });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isRequestingCode,
        login,
        logout,
        isLoading,
        isAuthing,
        beginAuth,
        cancelAuth,
        beginRequestCode,
        cancelRequestCode,
        error,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthContext, AuthProvider };
